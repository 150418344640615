import menuLogo from '../img/menu-logo.png';

const drawerWidth = 260;

const drawerMiniWidth = 80;

const menuTitle = 'Coligo LMS';
const menuLogoReference = '/';

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const containerFluid = {
  paddingInlineEnd: '15px',
  paddingInlineStart: '15px',
  marginInlineEnd: 'auto',
  marginInlineStart: 'auto',
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const container = {
  paddingInlineEnd: '15px',
  paddingInlineStart: '15px',
  marginInlineEnd: 'auto',
  marginInlineStart: 'auto',
  '@media (min-width: 768px)': {
    width: '750px',
  },
  '@media (min-width: 992px)': {
    width: '970px',
  },
  '@media (min-width: 1200px)': {
    width: '1170px',
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};

const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  margin: '25px 0',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  borderRadius: '6px',
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fff',
};

const defaultFont = theme => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: '300',
  lineHeight: '1.5em',
  textDecoration: 'none',
});

const primaryColor = '#9c27b0';
const warningColor = '#ff9800';
const dangerColor = '#f44336';
const darkenLightDangerColor = '#ef5350';
const successColor = '#4caf50';
const lightSuccessColor = '#66bb6a';
const infoColor = '#00acc1';
const roseColor = '#e91e63';
const grayColor = '#999999';
const dimgreyColor = '#696969';
const grayDisabled = '#b9b9b9';
const InfoBlue = '#2196f3';
const lightDangerColor = '#ffbaba';
const lightInfoColor = '#d5faff';
const kellyGreen = '#00bc33';
const yellowOrange = '#f8ae00';
const cherry = '#d50729';
const coolGrey = '#a5adb4';
const slateGrey = '#606e6d';
const deepAqua = '#086788';
const pinkishGrey = '#d1d1d1';
const hoverGrey = '#F5F5F5';
const skeletonPulseColor = 'rgba(0, 0, 0, 0.11)';
const defaultSkeltonColor = '#F0F0F0';

// Notifications colors
const unreadNotification = '#B4CDCD';
const unreadNotificationLight = '#95B9B9';

const whiteColor = '#FFFFFF';
const blackColor = '#000000';
const grayTitle = '#606E6D';
const graySubTitle = '#a4b8c4';
const grayLight = '#e7e8e8';

// Question States color
const publishedBackground = '#99CC99';
const publishedText = '#008000';
const reviewBackground = '#FFDB99';
const reviewText = '#CC8400';
const draftBackground = '#FFBABA';
const draftText = '#A70000';
const cancelledBackground = '#CCCCCC';
const cancelledText = '#333333';

// Assessments States color
const assessmentSubmittedBackground = '#FCF8E9';
const assessmentSubmittedText = '#EABD0D';
const assessmentDraftBackground = '#FFECDB';
const assessmentDraftText = '#F25C2E';
const assessmentCanceledBackground = '#FCEEED';
const assessmentCanceledText = '#E15554';
const assessmentFinishedBackground = '#DBF0FF';
const assessmentFinishedText = '#1B6DC6';
const assessmentStartedBackground = '#EBF7F1';
const assessmentStartedText = '#68C494';
// certificate states color
const certificateDeclinedText = '#E7957E';
const certificateDeclinedBackground = '#FFF9F8';
const certificateScheduledText = '#06AED5';
const certificateScheduledBackground = '#E5F4FF';
const certificateWaitingText = '#EDBB5B';
const certificateWaitingBackground = '#F3F7EB';
const certificateHiddenText = '#6F6F6F';
const certificateHiddenBackground = '#F3F3F3';
// Grades tab colors : Grid view
const newGradeColor = '#7FFF0070';
const gradedGradeColor = '#00A6ED60';
const excusedGradeColor = '#ffb40070';
const missedGradeColor = '#FF000070';

// attendance colors
const ashGrey = '#BABABA';
const darkJungleGreen = '#1d1d1d';
const steel = '#797E91';
const granite = '#808080';

// Dismissal colors
const notDismissedColor = '#860B0B';
const subTitle = '#5C5C5C';

const superAdminColor = 'linear-gradient(29deg, #be95c4, #5e548e)';
const superAdminButtonPrimary = '#5e548e';
const parentFilterBackgroundColor = '#F3F4FF';

const primaryBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
};
const infoBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)',
};
const successBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
};
const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
};
const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
};
const roseBoxShadow = {
  boxShadow:
    '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
};
const blueShadow = '#367493';

// old card headers
const orangeCardHeader = {
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
  ...warningBoxShadow,
};
const greenCardHeader = {
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
  ...successBoxShadow,
};
const redCardHeader = {
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
  ...dangerBoxShadow,
};
const blueCardHeader = {
  background: 'linear-gradient(60deg, #26c6da, #00acc1)',
  ...infoBoxShadow,
};
const purpleCardHeader = {
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  ...primaryBoxShadow,
};
// new card headers
const warningCardHeader = {
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
  ...warningBoxShadow,
};
const successCardHeader = {
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
  ...successBoxShadow,
};
const dangerCardHeader = {
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
  ...dangerBoxShadow,
};
const infoCardHeader = {
  background: 'linear-gradient(60deg, #26c6da, #00acc1)',
  ...infoBoxShadow,
};
const primaryCardHeader = {
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  ...primaryBoxShadow,
};
const roseCardHeader = {
  background: 'linear-gradient(60deg, #ec407a, #d81b60)',
  ...roseBoxShadow,
};
const superAdminCardHeader = {
  background: superAdminColor,
};

const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  ...defaultFont,
};

const cardHeader = {
  margin: '-20px 15px 0',
  borderRadius: '3px',
  padding: '15px',
};

const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s',
};

const tooltip = theme => ({
  color: '#FFFFFF',
  lineHeight: '1.7em',
  background: 'rgba(85,85,85,0.9)',
  border: 'none',
  borderRadius: '3px',
  boxShadow:
    '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  textShadow: 'none',
  textTransform: 'none',
  letterSpacing: 'normal',
  wordBreak: 'normal',
  wordSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'normal',
  lineBreak: 'auto',
});

const title = theme => ({
  color: '#3C4858',
  textDecoration: 'none',
  fontWeight: '300',
  marginTop: '30px',
  marginBottom: '25px',
  minHeight: '32px',
  fontFamily: theme.typography.fontFamily,
  '& small': {
    color: '#777',
    fontSize: '65%',
    fontWeight: '400',
    lineHeight: '1',
  },
});

const cardTitle = {
  ...title,
  marginTop: '0',
  marginBottom: '3px',
  minHeight: 'auto',
  '& a': {
    ...title,
    marginTop: '.625rem',
    marginBottom: '0.75rem',
    minHeight: 'auto',
  },
};

const cardSubtitle = {
  marginTop: '-.375rem',
};

const cardLink = {
  '& + $cardLink': {
    marginInlineStart: '1.25rem',
  },
};

export {
  // notification colors
  unreadNotification,
  unreadNotificationLight,
  // variables
  drawerWidth,
  drawerMiniWidth,
  menuLogo,
  menuTitle,
  menuLogoReference,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  lightDangerColor,
  darkenLightDangerColor,
  skeletonPulseColor,
  defaultSkeltonColor,
  successColor,
  lightSuccessColor,
  infoColor,
  lightInfoColor,
  whiteColor,
  dimgreyColor,
  blackColor,
  grayTitle,
  graySubTitle,
  roseColor,
  kellyGreen,
  yellowOrange,
  cherry,
  InfoBlue,
  grayColor,
  grayLight,
  grayDisabled,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  blueShadow,
  // old card header colors
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  // new card header colors
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,
  // custom card headers
  superAdminCardHeader,
  superAdminButtonPrimary,
  // Grades tab colors
  newGradeColor,
  gradedGradeColor,
  excusedGradeColor,
  missedGradeColor,
  // Question states color
  publishedBackground,
  publishedText,
  reviewBackground,
  reviewText,
  draftBackground,
  draftText,
  cancelledBackground,
  cancelledText,
  // Assessments states colors
  assessmentSubmittedBackground,
  assessmentSubmittedText,
  assessmentFinishedBackground,
  assessmentFinishedText,
  assessmentDraftBackground,
  assessmentDraftText,
  assessmentCanceledBackground,
  assessmentCanceledText,
  assessmentStartedBackground,
  assessmentStartedText,
  // certificate colors
  certificateDeclinedText,
  certificateDeclinedBackground,
  certificateScheduledText,
  certificateScheduledBackground,
  certificateWaitingText,
  certificateWaitingBackground,
  certificateHiddenText,
  certificateHiddenBackground,
  // zeplin palette
  coolGrey,
  slateGrey,
  deepAqua,
  pinkishGrey,
  hoverGrey,
  // attendance colors
  ashGrey,
  darkJungleGreen,
  steel,
  granite,
  // Dismissal colors
  notDismissedColor,
  parentFilterBackgroundColor,
  subTitle,
};
